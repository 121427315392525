import posed from 'react-pose'

const animateDuration = 300

const UpDown = posed.div({
  enter: {
    applyAtStart: {
      height: '100vh',
      overflowY: 'hidden',
      webkitAppearance: 'none',
    },
    applyAtEnd: {
      height: 'auto',
      overflowY: 'auto',
      webkitAppearance: 'auto',
    },
    y: ({ order_scroll, previous_order }) =>
      previous_order < parseFloat(order_scroll) ? '0%' : '0%',
    delay: 0,
    beforeChildren: true,
    transition: ({ order_scroll, previous_order }) =>
      previous_order < parseFloat(order_scroll)
        ? {
            type: 'keyframes',
            values: ['100%', '0%'],
            duration: animateDuration,
          }
        : {
            type: 'keyframes',
            values: ['-100%', '0%'],
            duration: animateDuration,
          },
  },
  exit: {
    applyAtStart: {
      height: '100vh',
      overflowY: 'hidden',
      webkitAppearance: 'none',
    },
    applyAtEnd: {
      height: 'auto',
      overflowY: 'auto',
      webkitAppearance: 'auto',
    },
    y: ({ order_scroll, previous_order }) =>
      previous_order < parseFloat(order_scroll) ? '-100%' : '100%',
    delay: 0,
    transition: {
      duration: animateDuration,
    },
  },
})

const Fade = posed.div({
  // enter: { opacity: 1 },
  // exit: { opacity: 0 },
})

const DefaultPose = UpDown

export { Fade, DefaultPose }
