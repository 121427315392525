import React from 'react'
import { Link } from 'gatsby'
import _variables from '../../scss/common/_variables.scss'
import PropTypes from 'prop-types'
import arrowRight from '../../images/arr-r.png'
import arrowLeft from '../../images/arr-l.png'
//import arrowDown from '../../images/arr-d.png'

const SolidButton = ({ label, url, btnwidth, prev, order, arrow }) => (
  <Link
    to={url}
    className="solidButton"
    style={{
      padding: '10px 20px',
      fontWeight: '600',
      textTransform: 'uppercase',
      textAlign: 'center',
      width: btnwidth ? btnwidth : 'auto',
    }}
    state={{
      order_scroll: order || 1,
      previous_order: prev || 0,
    }}
  >
    {arrow &&
      arrow === 'left' && (
        <img
          src={arrowLeft}
          style={{
            paddingBottom: 3,
            paddingRight: 20,
          }}
        />
      )}
    {label}
    {arrow &&
      arrow === 'right' && (
        <img
          src={arrowRight}
          style={{
            paddingBottom: 3,
            paddingLeft: 20,
          }}
        />
      )}

    {/* {arrow &&
      arrow === 'down' && (
        <img
          src={arrowDown}
          style={{
            paddingBottom: 3,
            paddingLeft: 20,
          }}
        />
      )} */}
  </Link>
)

export default SolidButton

SolidButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  btnwidth: PropTypes.string,
  prev: PropTypes.number,
  order: PropTypes.number,
}
