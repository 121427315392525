import React, { Component } from 'react'
import PropTypes from 'prop-types'
class WhiteButton extends Component {
  onClick = () => {
    this.props.onCloseNav()
    this.props.onShowModal()
  }

  render() {
    return (
      <span
        onClick={this.onClick}
        className="whiteButton"
        style={{
          background: '#fff',
          padding: '10px 20px',
          display: 'inline-block',
          fontWeight: '600',
          textTransform: 'uppercase',
          width: this.props.btnwidth || 'auto',
          margin: '10px 0',
        }}
      >
        <i className="fas fa-download" style={{ marginRight: 10 }} />{' '}
        {this.props.label}
      </span>
    )
  }
}

export default WhiteButton

WhiteButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  btnwidth: PropTypes.string,
  prev: PropTypes.number,
  order: PropTypes.number,
}
